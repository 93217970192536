export default function useDocumentActions() {
  const { isDirectSign, directSignB64AuthHeader } = storeToRefs(useDirectSignStore())
  const { email } = storeToRefs(useUserStore())

  const {
    documentRepository: docsRepo,
    directDocumentRepository: directDocsRepo,
    srRepository: srRepo,
    directSrRepository: directSrRepo,
  } = useApi()

  const downloadDocuments = async (id: string, documentIds: string[], isSigned?: boolean) => {
    if (documentIds.length === 1) {
      const { data, name } = isDirectSign.value
        ? await directDocsRepo.getDocumentData(documentIds[0], directSignB64AuthHeader.value, isSigned)
        : await docsRepo.getDocumentData(documentIds[0], isSigned)
      downloadFile(data, name)
    } else {
      const { data, name } = isDirectSign.value
        ? await directSrRepo.getDocumentsZip(id, directSignB64AuthHeader.value, isSigned)
        : await srRepo.getDocumentsZip(id, isSigned)
      downloadFile(data, name)
    }
  }

  const downloadProtocol = async (id: string, type: SignatureProtocolType = 'pdf') => {
    const { data, name } = isDirectSign.value
      ? await directDocsRepo.getProtocol(id, type, directSignB64AuthHeader.value)
      : await docsRepo.getProtocol(id, type)

    downloadFile(data, name)
  }

  const downloadAttachment = async (id: string, attachmentId: string, name?: string) => {
    const { data, name: attachmentName } = isDirectSign.value
      ? await directDocsRepo.getAttachment(id, attachmentId, directSignB64AuthHeader.value)
      : await docsRepo.getAttachment(id, attachmentId)

    downloadFile(data, name ?? attachmentName)
  }

  const assign = async (
    srData: SignatureRequestData,
    recipient: string,
    message: string
  ): Promise<DocumentActionResult> => {
    const signatureId = srData.signatures.find(
      sig => sig.accountEmail === email.value && sig.statusCode === 'OPEN'
    )?.sid

    if (!signatureId) throw new Error('No signature of assigning user found')

    const { status } = await srRepo.delegate(srData.id, {
      sid: signatureId,
      to: [recipient],
      message,
    })

    return status
  }

  const decline = async (id: string, message?: string): Promise<DocumentActionResult> => {
    const { status } = isDirectSign.value ? await directSrRepo.decline(id, message) : await srRepo.decline(id, message)

    return status
  }

  const withdraw = async (id: string, message?: string): Promise<DocumentActionResult> => {
    const { status } = await srRepo.withdraw(id, message)

    return status
  }

  const remove = async (id: string): Promise<DocumentActionResult> => {
    const { status } = await srRepo.remove(id)

    return status
  }

  const batchRemove = async (ids: string[]) => {
    await Promise.all(ids.map(id => remove(id)))
  }

  const remind = async (id: string) => {
    await srRepo.remindSigners(id)
  }

  const inviteSigner = async (id: string, email: string, documentIds: string[]) => {
    await srRepo.inviteSigner(id, email, documentIds)
  }

  const removeSigner = async (id: string, signatureId: string) => {
    await srRepo.removeSigner(id, signatureId)
  }

  return {
    downloadDocuments,
    downloadProtocol,
    downloadAttachment,
    assign,
    decline,
    withdraw,
    remove,
    batchRemove,
    remind,
    inviteSigner,
    removeSigner,
  }
}
